import React, { Fragment } from 'react';
import GenericTableCell from "../common/generic/table/cell/GenericTableCell";
import GenericTable, { GenericTableRow } from "../common/generic/table/GenericTable";
import GenericTableBody from "../common/generic/table/GenericTableBody";
import GenericTableHeadSingleRow from "../common/generic/table/GenericTableHeadSingleRow";
import { useChronoApi } from "../../auth/apiEffects";
import { LoadingIndicator } from "../LoadingIndicator";
import { Row, Col } from 'react-bootstrap';
import { Header } from './BilletterieCompte';
import { convertDateToEasterTime } from "../common/helper";
import styled from 'styled-components';

const FormRow = styled(Row)`
    margin-top: 5px;
`

interface IFormattedTicketsDetail {
    activationDate: string;
    validAmountInSecond: number;
    ticketUniqueNumber: string;
    organization: string;
    ticketName: string;
    amountPassageLeft: string;
    creationDate: string;
    expirationDate: string;
    validationUniqueNumber: string;
}

export interface IFormattedTickets {
    dateCommande: string;
    details: IFormattedTicketsDetail[];
}
const ClientWallet = ({searchParams, validated}) => {
    const {data: tickets, isLoading } = useChronoApi(`/titre/historique?${searchParams}`);

    const ticketWithData = tickets.filter(ticket => ticket.amountPassageLeft > 0)

    const formattedTickets: IFormattedTickets[] = [];
    
    const uniqueDates = (value, index, self) => {
        return self.indexOf(value) === index;
    }

    if (ticketWithData.length > 0) {
        const onlyDates = ticketWithData.map(ticket => convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD'))
        const unique = onlyDates.filter(uniqueDates);
        unique.map(date => formattedTickets.push({
                dateCommande: date, 
                details: ticketWithData.filter(ticket => convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD') === date 
            )}
        ))          
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            {
                formattedTickets.length > 0 &&
                <div style={{padding: "0px 10px 0px 10px"}}>
                    {
                        formattedTickets.sort((a, b) => new Date(b.dateCommande).valueOf() - new Date(a.dateCommande).valueOf()).map((item, i) => {
                            return (
                                <div key= {i}>
                                    <FormRow>
                                        <Header>{item.dateCommande}</Header>
                                    </FormRow>
                                    <FormRow>
                                        <GenericTable style={{marginBottom:'0px', width:'100%'}}>
                                            <GenericTableHeadSingleRow style={{ backgroundColor:'#DCDCDC'}}>
                                                <th>Organisation</th>
                                                <th>Nom du titre</th>
                                                <th>Identifiant titre</th>
                                                <th>Passages restants</th>
                                                <th>Date et heure achat</th>
                                                <th>Durée validité</th>
                                            </GenericTableHeadSingleRow>
                                            <GenericTableBody key={i + '_inside'} style={{ borderTop: 'none' }}>
                                                {
                                                    item.details.sort((a, b) => new Date(b.activationDate).valueOf() - new Date(a.activationDate).valueOf()).map((ticket, j) => {
                                                        const totalValidTime = ticket.validAmountInSecond / 60 / 60;
                                                        let displayUnits = 'minutes';
                                                        if (totalValidTime === 1 ) displayUnits = 'heure';
                                                        if (totalValidTime > 1 ) displayUnits = 'heures';

                                                        return (
                                                            <Fragment key={j}>
                                                                <GenericTableRow key={ticket.ticketUniqueNumber}>
                                                                    <GenericTableCell>{ticket.organization}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.ticketName}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.ticketUniqueNumber}</GenericTableCell>
                                                                    <GenericTableCell>{ticket.amountPassageLeft}</GenericTableCell>
                                                                    <GenericTableCell>{convertDateToEasterTime(ticket.creationDate, 'YYYY-MM-DD HH:mm')}</GenericTableCell>
                                                                    <GenericTableCell>{totalValidTime}&nbsp;{displayUnits}</GenericTableCell>
                                                                </GenericTableRow>
                                                            </Fragment>
                                                        )
                                                    })
                                                }
                                            </GenericTableBody>
                                        </GenericTable>
                                    </FormRow>
                                </div>
                            )
                        })
                    }
                </div>
            }
            {
                (!formattedTickets || formattedTickets.length === 0) && validated &&
                    <Row>
                        <Col style={{textAlign: 'center'}}>
                            <h3>Aucun résultat</h3>
                        </Col>
                    </Row>
            }
        </Fragment>
    )}

export default ClientWallet;