/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Card, Button, Form, Collapse } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import useResources from "../../../../lib/hooks/useResources";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faFilter, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import  SelectLastReceptionDate  from '../../../common/generic/select/SelectLastReceptionDate'
import { Input, InputDate} from '../../../../lib/ui/index'
import styled from 'styled-components';
import { getApplicationLogDetails, getDropdownListItems } from "../../../../redux/actions/applicationLogs/requestApplicationLogDetails";
import useUI from "../../../../lib/hooks/useUI";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import * as constantesDetails from "../../../../redux/keys/applicationLogs/applicationLogDetails";
import * as constantesLogs from "../../../../redux/keys/applicationLogs/applicationLogs";
import AutoComplete from '../../../common/autocomplete';
import { convertDateToEasterTime } from "../../../common/helper";
import GenericDate from 'components/common/generic/GenericDate';

import moment from 'moment';


const FormRow = styled(Row)`
    margin-top: 30px;
    margin-bottom: 20px;
`

const ErrorMessage = styled.p`
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
`;
const DISPLAY_DATE_FORMAT = "YYYY-MM-DD"
const SERVER_DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"

const validationSchema = yup.object().shape({
    startDate: yup.date().required(),
    endDate: yup
        .date()
        .required()
        .test(
            "is-greater",
            "La date de fin ne peut être antérieure",
            function (value) {
                const { startDate } = this.parent;
                return moment(value).isSameOrAfter(startDate);
            }),
}); 

interface ISummaryParam {
    startDate: string;
    endDate: string;
    logdateFilter: string;
}

interface IElementSelected {
    selectedIndex: number;
}

interface IApplicationLogs {
    logMobileConfigurationId: string;
}

export const FilterDetails = () => {
    const [collapse, setCollapse] = useState(false);

    const summaryParam = useUI(constantesDetails.LOG_SUMMARY_PARAMS) as ISummaryParam;


    const formDefaultConfig = {
        defaultValues: {
            startDate: summaryParam ?  convertDateToEasterTime(summaryParam.startDate) : moment().subtract(1, 'hour').format(SERVER_DATE_FORMAT),
            endDate: summaryParam ? convertDateToEasterTime(summaryParam.endDate) : moment().format(SERVER_DATE_FORMAT),
            searchValue: "",
            versionApp: "",
            osVersion: "",
            phoneBrand: "",
            ddlLastReceptionDate: summaryParam ? summaryParam.logdateFilter : '1h'
        },
        reValidateMode: 'onChange',
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    };

    const { handleSubmit, watch, errors, control, setValue } = useForm(formDefaultConfig as any);
    const values = watch();
    const [desactivateCustomDate, setDesactivateCustomDate] = useState(values.ddlLastReceptionDate != 0);

    const dispatch = useDispatch();
    const applicationLogs = useResources(constantesLogs.APPLICATION_LOGS) as IApplicationLogs[];   
    const elementSelected = useUI(constantesDetails.SELECTED_APPLICATION_LOG_INDEX) as IElementSelected;

    useEffect(() => {
        fetchData();
    }, [elementSelected, applicationLogs]);

    const onChangeLastReceptionDate = (obj) => {
        setValue("startDate", obj && obj.startDate ? obj.startDate : moment().format(DISPLAY_DATE_FORMAT));
        setValue("endDate", obj && obj.endDate ? obj.endDate : moment().format(DISPLAY_DATE_FORMAT));
        setValue("ddlLastReceptionDate", obj && obj.value ? obj.value : '1h')
        setDesactivateCustomDate(obj.value !== '0')
    }

    const fetchData = () => {
        if (elementSelected && elementSelected.selectedIndex >= 0 && applicationLogs && applicationLogs.length > 0)

            dispatch(getApplicationLogDetails(constantesDetails.APPLICATION_LOG_DETAILS, {
                StartDate: moment(values.startDate).utc().format(SERVER_DATE_FORMAT),
                EndDate: moment(values.endDate).utc().format(desactivateCustomDate ? SERVER_DATE_FORMAT : "YYYY-MM-DD 23:59:59"),
                LogMobileConfigurationId: applicationLogs[elementSelected.selectedIndex].logMobileConfigurationId,
                PhoneBrand: values.phoneBrand,
                OsVersion: values.osVersion,
                AppVersion: values.versionApp,
                SearchCriteria: values.searchValue
            }));
    }

    return (
        <Fragment>
            {
                <div className='config-mt-15' >

                    <Card>
                        <Card.Header onClick={() => { setCollapse(!collapse); }}>
                            <span className='header-filter-config'>
                            <FontAwesomeIcon icon={faFilter} /> Filtres
                        </span>

                            <Button variant="light" className='btn-filter-collapse' onClick={() => { setCollapse(!collapse);}}>
                                <FontAwesomeIcon icon={collapse ? faPlus : faMinus} />
                        </Button>

                        </Card.Header>
                        <Collapse in={!collapse}>
                            <Card.Body style={{ paddingBottom: '10px' }}>
                            <Form data-testid="frmDetailsSearch"
                                onSubmit={handleSubmit(  () => {
                                     fetchData();
                                })}
                            >
                                <FormRow className='config-mt-10'>
                                        <Col xl={6}  md={12} className="pl-0" >
                                    <Controller
                                        control={control}
                                        name="searchValue"
                                        value={values.searchValue}
                                        render={({ onChange, value }) => {
                                            return (
                                                <Input
                                                    label="Message / Exception"
                                                    value={value}
                                                    onChange={onChange}
                                                    maxLength="200"
                                                    placeholder="search"
                                                    data_testid="detailSearch"
                                                />
                                            );
                                        }}
                                    />
                                </Col>

                                        <Col xl={3}  md={12} className="pl-0">

                                    <Controller
                                        control={control}
                                        name="versionApp"
                                        value={values.logType}
                                        render={({ onChange, value }) => {
                                            return (
                                                <AutoComplete value={value}
                                                    label="Version d'app"
                                                    onChange={onChange}
                                                    propertyname={'VersionApp'}
                                                    style={{ width: '130px' }}
                                                    id='logDetailFilter_VersionApp_AutoComplete'
                                                    getDropdownListItems={getDropdownListItems}
                                                />
                                            );
                                        }}
                                    />
                                </Col>

                                        <Col xl={3} md={12} className="pl-0">

                                            <Controller
                                                control={control}
                                                name="osVersion"
                                                value={values.osVersion}
                                                render={({ onChange, value }) => {
                                                    return (
                                                        <AutoComplete value={value}
                                                            label="Os version"
                                                            onChange={onChange}
                                                            propertyname={'OSVersion'}
                                                            style={{ width: '130px' }}
                                                            id='logDetailFilter_OSVersion_AutoComplete'
                                                            getDropdownListItems={getDropdownListItems}
                                                        />
                                                    );
                                                }}
                                            />
                                </Col>
                            </FormRow>

                            <FormRow>

                                <Col xl={6} md={12} className="pl-0">
                                    <Controller
                                        control={control}
                                                name="ddlLastReceptionDate"
                                                value={values.ddlLastReceptionDate}
                                        render={({ onChange, value }, { invalid }) => {
                                            return (
                                                <SelectLastReceptionDate value={value} onChange={(evt) => { onChangeLastReceptionDate(evt)}} />
                                            );
                                        }}
                                    />
                                </Col>

                                <Col xl={3} md={12} className="pl-0">
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        value={values.startDate}
                                        render={({ onChange, value }, { invalid }) => {
                                            return (
                                                <GenericDate
                                                    label="Du"
                                                    date={value}
                                                    onDateChange={onChange}
                                                    width={80}
                                                    canUserEdit={!desactivateCustomDate}
                                                    isDisabled={desactivateCustomDate}
                                                    //format={DISPLAY_DATE_FORMAT}
                                                    //title="start date"

                                                />
                                            );
                                        }}
                                    />

                               </Col>
                                <Col xl={3} md={12} className="pl-0">
                                    <Controller
                                        control={control}
                                        name="endDate"
                                            value={values.endDate}
                                        render={({ onChange, value }, { invalid }) => {
                                            return (
                                                <GenericDate
                                                    label="Au"
                                                    date={value}
                                                    onDateChange={onChange}
                                                    width={80}
                                                    canUserEdit={!desactivateCustomDate}
                                                    isDisabled={desactivateCustomDate}
                                                    //format={DISPLAY_DATE_FORMAT}
                                                    //title="end date"
                                                />
                                            );
                                        }}
                                    />
                                    {errors.endDate && <ErrorMessage>{errors.endDate.message}</ErrorMessage>}
                                </Col>


                            </FormRow>

                            <FormRow>
                                <Col xl={4} className="pl-0">

                                    <Controller
                                        control={control}
                                        name="phoneBrand"
                                        value={values.phoneBrand}
                                        render={({ onChange, value }) => {
                                            return (
                                                <AutoComplete value={value}
                                                    label="Marque téléphone"
                                                    onChange={onChange}
                                                    propertyname={'PhoneBrand'}
                                                    style={{ width: '160px' }}
                                                    id='logDetailFilter_PhoneBrand_AutoComplete'
                                                    getDropdownListItems={getDropdownListItems}
                                                />
                                            );
                                        }}
                                    />
                                </Col>

                                        <Col xl={2} lg={2} md={11} className='pl-0' style={{marginLeft:'10px'}}>
                                    <Button type="submit">
                                        <FontAwesomeIcon icon={faSearch} /> Rechercher
                                    </Button>
                                </Col>

                        </FormRow>
                        </Form>
                            </Card.Body>
                    </Collapse>
                    </Card>
                </div>

            }
        </Fragment>
    );

}

export default FilterDetails;
