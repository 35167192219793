import React, { Fragment, useState } from 'react';
import { LoadingIndicatorInApp } from "../../../LoadingIndicator";
import { Row, Col, Button } from 'react-bootstrap';
import { convertDateToEasterTime } from "../../../common/helper";
import useUI from "../../../../lib/hooks/useUI";
import useResources from "../../../../lib/hooks/useResources";
import Grid from "../../../../lib/ui/Grid/Grid";
import * as constantesDetails from "../../../../redux/keys/applicationLogs/applicationLogDetails";
import * as requestStatus from "../../../../redux/sagas/requestStatus";
import PopupDetails from './PopupDetails'

interface IApplicationLogDetail {


}

const ApplicationLogDetailsGrid = () => {
    const applicationLogDetails = useResources(constantesDetails.APPLICATION_LOG_DETAILS) as IApplicationLogDetail[];
    const status = useUI(constantesDetails.APPLICATION_LOG_DETAILS + '_STATUS');
    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [btnText, setBtnText] = useState('');


    if (status === requestStatus.REQUEST_PENDING) {
        return <LoadingIndicatorInApp />;
    }

    const columns = [
        {
            Header: "Message",
            accessor: "message",
            Cell: props => <div>
                    <Button id={'btnLodDeailMess_' + props.row.index} variant="link" onClick={() => {
                        setTitle("Détails message d'erreur");
                        setText(props.value);
                        setShowModal(true);
                        setBtnText('Copier le message')
                    }}>{props.value}</Button>
                </div>
        },
        {
            Header: "Exception",
            accessor: "exception",
            Cell: props => <div>
                <Button id={'btnLodDeailException_' + props.row.index} variant="link" onClick={() => {
                     setTitle("Détails exception");
                    setText(props.value);
                    setShowModal(true);
                    setBtnText("Copier l'exception")
            }
                }>{props.value}</Button>
            </div>
        },
        {
            Header: "Nom écran",
            accessor: "screenName"
        },
        {
            Header: "Version d'app",
            accessor: "versionApp",
        },
        {
            Header: "Version OS",
            accessor: "osVersion",
        },
        {
            Header: "Date",
            accessor: "logDate",
            Cell: props => <span>{props.value ? convertDateToEasterTime(props.value, 'YYYY-MM-DD HH:mm') : ''}</span>
        },
        {
            Header: "Marque téléphone",
            accessor: "phoneBrand",
        },
    ];
    return (
        <Fragment>
            {
                applicationLogDetails && applicationLogDetails.length > 0 &&
                <div>
                    <PopupDetails id="detaippopup" showModal={showModal} title={title} btnText={btnText} text={text}  hideShowModal={() => { setShowModal(false) }} />
                    <Grid columns={columns} data={applicationLogDetails} showExport={true} csvFileName={'logsDetails'} csvSeparator={'|'}/>
                </div>
            }
            {
                (!applicationLogDetails || applicationLogDetails.length === 0)  &&
                <Row>
                    <Col style={{ textAlign: 'center' }}>
                        <h3>Aucun résultat</h3>
                    </Col>
                </Row>
            }

        </Fragment>
    )
}

export default ApplicationLogDetailsGrid;